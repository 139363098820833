import React from "react"
import "./404.scss"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Link from "gatsby-link"

const NotFoundPage = () => (
  <Layout>
    <SEO title="Not found" />
    <div className="wrapper">
      <section className="error-container">
        <span className="four">
          <span className="screen-reader-text">4</span>
        </span>
        <span className="zero">
          <span className="screen-reader-text">0</span>
        </span>
        <span className="four">
          <span className="screen-reader-text">4</span>
        </span>
      </section>
      <h5 className="text-center">
        The page you are looking for does not exist.
      </h5>
      <div className="link-container">
        <Link to="/">Go to home</Link>
      </div>
    </div>
  </Layout>
)

export default NotFoundPage
